import Socialbar from "../socialbar/socialbar"
import { HashLink } from 'react-router-hash-link';

const Footer = () => {

    return (
        <footer className="footer items-center md:mt-16 lg:mt-16 p-4 dark:border-solid border-0 border-t border-black dark:border-white">
            <div className="items-center grid-flow-col">
            <HashLink smooth to="/#" className="btn btn-ghost normal-case text-xl dark:text-white">Makhdoomzada Ali Syed</HashLink>
            </div> 
            <div className="grid-flow-col gap-4 ml-2 md:place-self-center md:justify-self-end">
               <Socialbar></Socialbar>
                </div>
        </footer>
    )
}

export default Footer  