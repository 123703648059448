import React from "react"
import Socialbar from '../../components/socialbar/socialbar';
import Switcher from "../switcher/switcher";
import { HashLink } from 'react-router-hash-link';

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -64; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}

const Navbar = () => {

    return (
<div className="navbar bg-transparent backdrop-filter backdrop-blur-lg bg-opacity-30 sticky top-0 z-10 dark:bg-transparent dark:backdrop-filter dark:backdrop-blur-lg dark:bg-opacity-30 dark:text-white transition-colors duration-300">
  <div className="navbar-start">
    <div className="dropdown">
      <label tabIndex={0} className="btn btn-ghost lg:hidden btn-sm">
        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
      </label>
      <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 dark:bg-lightgrey">
      <li><HashLink smooth to="/#projects" scroll={scrollWithOffset}>Projects</HashLink></li>
      <li><HashLink smooth to="/#contact" scroll={scrollWithOffset}>Contact</HashLink></li>
        <li><a>Resume</a></li>
      </ul>
    </div>
    <HashLink smooth to="/#" className="btn btn-ghost normal-case text-[14px] lg:text-xl md-text-xl">Makhdoomzada Ali Syed</HashLink>
  </div>
  <div className="navbar-center hidden lg:flex">
    <ul className="menu menu-horizontal px-1">

    <li><HashLink smooth to="/#projects" scroll={scrollWithOffset}>Projects</HashLink></li>
    <li><HashLink smooth to="/#contact" scroll={scrollWithOffset}>Contact</HashLink></li>
    <li><a>Resume</a></li>
    </ul>
  </div>
  <div className="navbar-end">
    <div className="mt-0.5 mr-3 md:mt-0 md:mr-0 lg:mt-0 lg:mr-0">
    <Switcher/>
    </div>
    <div className="mb-0.5">
    <Socialbar></Socialbar>
    </div>
  </div>
</div>
    )
}

export default Navbar