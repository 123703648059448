const macbook = require("../../../src/images/Robotics.png");
const iphone = require("../../../src/images/iphonevertical1.png");
const mac = require("../../../src/images/HonkMain.png");

const Card = () => {
  return (
    <div className="p-2 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">
      <div className="rounded overflow-hidden shadow-lg">
        <a
          href="https://i.imgur.com/qM4lDLb.jpg"
          target="_blank"
          rel="noreferrer noopener"
          role="button"
        >
          {" "}
          <img
            className="hover:scale-125 transition-all duration-500 cursor-pointer rounded w-full scale-100"
            src={mac}
            alt="Forest"
          ></img>{" "}
        </a>
        <div className="px-6 py-4">
          <a
            href="https://i.imgur.com/qM4lDLb.jpg"
            target="_blank"
            rel="noreferrer noopener"
            role="button"
          >
            <div className="font-bold text-xl mb-2 font-inter dark:text-white transition-colors duration-300">
              Honk
              <svg
                className="w-4 h-4 inline-block ml-2 mb-0.5 dark:fill-white transition-colors duration-300"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                {" "}
                <path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
              </svg>
            </div>
          </a>
          <p className="text-gray-700 text-base dark:text-white dark:text-opacity-60 transition-colors duration-300">
            An academic alternative to random chat platforms. Designed and
            developed for one-on-one video chats, enabling academic discussions
            and study partnerships. With institutional verification, it
            guarantees safety while prioritizing educational engagement.
          </p>
        </div>
        <div className="px-6 pt-4 pb-2">
          <span className="inline-block bg-gray rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 dark:text-white dark:bg-lightgrey transition-colors duration-300">
            Typescript
          </span>
          <span className="inline-block bg-gray rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 dark:text-white dark:bg-lightgrey transition-colors duration-300">
            Go
          </span>
          <span className="inline-block bg-gray rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 dark:text-white dark:bg-lightgrey transition-colors duration-300">
            Next.js
          </span>
          <span className="inline-block bg-gray rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 dark:text-white dark:bg-lightgrey transition-colors duration-300">
            Gin
          </span>
          <span className="inline-block bg-gray rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 dark:text-white dark:bg-lightgrey transition-colors duration-300">
            TailwindCSS
          </span>
        </div>
      </div>
      <div className="rounded overflow-hidden shadow-lg">
        <a
          href="https://cheemarobotics.com/"
          target="_blank"
          rel="noreferrer noopener"
          role="button"
        >
          <img
            className="hover:scale-125 transition-all duration-500 cursor-pointer rounded w-full scale-100 "
            src={macbook}
            alt="Macbook"
          ></img>
        </a>
        <div className="px-6 py-4">
          <a
            href="https://cheemarobotics.com/"
            target="_blank"
            rel="noreferrer noopener"
            role="button"
          >
            <div className="text-black font-bold text-xl mb-2 font-inter dark:text-white transition-colors duration-300 inline-block">
              Cheema Robotics
              <svg
                className="w-4 h-4 inline-block ml-2 mb-0.5 dark:fill-white transition-colors duration-300"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                {" "}
                <path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
              </svg>
            </div>
          </a>
          <p className="text-gray-700 text-base dark:text-white dark:text-opacity-60 transition-colors duration-300">
            A SaaS email account generation automation software which allows for
            a efficient solution that streamlines the process of creating and
            managing email accounts, resulting in increased productivity for
            over 1000+ concurrent users.
          </p>
        </div>
        <div className="px-6 pt-4 pb-2">
          <span className="inline-block bg-gray rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 dark:text-white dark:bg-lightgrey transition-colors duration-300">
            Python
          </span>
          <span className="inline-block bg-gray rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 dark:text-white dark:bg-lightgrey transition-colors duration-300">
            Requests
          </span>
          <span className="inline-block bg-gray rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 dark:text-white dark:bg-lightgrey transition-colors duration-300">
            Selenium
          </span>
        </div>
      </div>
      <div className="rounded overflow-hidden shadow-lg">
        <a
          href="https://github.com/utkm/GeoSMS"
          target="_blank"
          rel="noreferrer noopener"
          role="button"
        >
          {" "}
          <img
            className="hover:scale-125 transition-all duration-500 cursor-pointer rounded w-full scale-100"
            src={iphone}
            alt="Forest"
          ></img>
        </a>
        <div className="px-6 py-4">
          <a
            href="https://github.com/utkm/GeoSMS"
            target="_blank"
            rel="noreferrer noopener"
            role="button"
          >
            <div className="font-bold text-xl mb-2 font-inter dark:text-white transition-colors duration-300">
              Geo SMS
              <svg
                className="w-4 h-4 inline-block ml-2 mb-0.5 dark:fill-white transition-colors duration-300"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                {" "}
                <path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
              </svg>
            </div>
          </a>
          <p className="text-gray-700 text-base dark:text-white dark:text-opacity-60 transition-colors duration-300">
            A server-side app that enables users to perform various emergency
            searches offline such as directions, transit, search-queries, crypto
            prices and more using the SMS protocol.
          </p>
        </div>
        <div className="px-6 pt-4 pb-2 ">
          <span className="inline-block bg-gray rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 dark:text-white dark:bg-lightgrey transition-colors duration-300">
            Python
          </span>
          <span className="inline-block bg-gray rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 dark:text-white dark:bg-lightgrey transition-colors duration-300">
            Flask
          </span>
          <span className="inline-block bg-gray rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 dark:text-white dark:bg-lightgrey transition-colors duration-300">
            Requests
          </span>
        </div>
      </div>
    </div>
  );
};

export default Card;
