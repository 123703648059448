
import { useState } from "react";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import useDarkMode from "../../hooks/useDarkMode";
  
export default function Switcher() {
    const [colorTheme, setTheme] = useDarkMode();
    const [darkSide, setDarkSide] = useState(
        colorTheme === "light" ? true : false
    );
  
    const toggleDarkMode = (checked) => {
        setTheme(colorTheme);
        setDarkSide(checked);
    };
  
    return (
        <a className="btn btn-ghost btn-square btn-xs md:btn-md lg:btn-md">
            <DarkModeSwitch
                className="w-5 h-5 md:w-7 md:h-7 lg:w-7 lg:h-7"
                checked={darkSide}
                onChange={toggleDarkMode}
            />
        </a>
    );
}