import { HashLink } from 'react-router-hash-link';

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -64; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}

const Hero = () => {

    return (
        
    <section className="bg-transparent content-center">
        <div className="grid max-w-screen-xl px-7 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
            <div className="mr-auto place-self-center lg:col-span-7">
                <h1 className="max-w-xl mb-4 text-[29px] font-bold font-inter tracking-tight leading-none md:text-5xl xl:text-5xl dark:text-white transition-colors duration-300">Hi, im<br/><text className="bg-gradient-to-br from-aurora1 via-aurora2 via-aurora3 via-aurora4 to-aurora5 bg-clip-text text-transparent">Makhdoomzada Ali Syed</text><br/>nice to meet you! 👋</h1>
                <p className="max-w-2xl mb-6 font-inter text-[12px] text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-white transition-colors duration-300">I am a current student attending the <br />
                    <span style={{ color: '#FFD700' }}>University Of Waterloo</span> for <span style={{ color: '#FF0000' }}>Computer Science</span><br />
                    and <span style={{ color: '#A020F0' }}>Wilfrid Laurier University</span> for <span style={{ color: '#6c541e' }}>Business Administration</span>.<br />
                    I am a software developer interested and passionate<br />
                    about full-stack development and cybersecurity.</p>
                <HashLink smooth to="/#contact" scroll={scrollWithOffset} href="#" className=" inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-base-100 rounded-lg bg-gradient-to-br from-aurora1 via-aurora2 via-aurora3 via-aurora4 to-aurora5 hover:ring-4 hover:ring-aurora4 dark:hover:ring-aurora4 transition-colors duration-300">
                    Contact
                    <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </HashLink>
            </div>
            <div className="hidden lg:mt-0 lg:col-span-5 lg:flex ">
                <img src="https://i.imgur.com/mjfrFNZ.png" alt="mockup"></img>
            </div>                
        </div>
    </section>
    )
}

export default Hero