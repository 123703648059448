import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toast.css';

const Form = () => {
    const showToastMessage = () => {
        console.log(localStorage.theme)
        if (localStorage.theme === 'light') {
            toast('Message Sent Successfully!', {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        } else {
            toast.dark('Message Sent Successfully!', {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    const form = useRef<HTMLFormElement | null>(null);
  
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_u9253nj', 'template_6apagpi', form.current!, 'iuC5Mwbw7S9alhQZK')
        .then((result) => {
            console.log(result.text);
            showToastMessage();
        }, (error) => {
            console.log(error.text);
        });
    };

    return (
        <section className=" text-gray-800">
        <div className="flex flex-wrap">
          <div className="md:mt-14 lg:mt-14 grow-0 shrink-0 basis-auto mb-6 md:mb-0 w-full md:w-6/12 px-3 lg:px-6">
            <h2 className="text-[20px] md:text-3xl lg:text-3xl px-10 md:px-16 font-bold md:mb-6 lg:mb-6 font-inter dark:text-white transition-colors duration-300">Lets get in touch!</h2>
            <p className="text-gray-500 mb-3 md:mb-6 px-10 md:px-16 lg:px-16 dark:text-white transition-colors duration-300">
            Thank you for visiting my website. If you have any questions, comments, or just want to say hello, please don't hesitate to reach out. You can contact me by filling out the form to the right.
            </p>
            <p className="text-gray-500 mb-2 px-10 md:px-16 lg:px-16 dark:text-white transition-colors duration-300">Waterloo, Ontario, Canada</p>
            <p className="text-gray-500 mb-2 px-10 md:px-16 lg:px-16 dark:text-white transition-colors duration-300">ali.snaqvi09@gmail.com</p>
          </div>
          <div className="grow-0 shrink-0 basis-auto mb-12 md:mb-0 w-full md:w-6/12 px-3 lg:px-6">
          <div className="w-full md:w-96 md:max-w-full mx-auto">
  <div className="p-6 border border-gray-300 rounded-md dark:border-white transition-colors duration-300">
    <form ref={form} onSubmit={sendEmail}>
      <label className="block mb-6">
        <span className="text-gray-700 dark:text-white transition-colors duration-300">Your name</span>
        <input
          type="text"
          name="from_name"
          className="
            block
            w-full
            mt-1
            border-gray-300
            rounded-md
            shadow-sm
            focus:border-aurora4
            focus:ring
            focus:ring-aurora4
            focus:ring-opacity-100
          "
          placeholder="Ali Syed"
        />
      </label>
      <label className="block mb-6">
        <span className="text-gray-700 dark:text-white transition-colors duration-300">Email address</span>
        <input
          name="user_email"
          type="email"
          className="
            block
            w-full
            mt-1
            border-gray-300
            rounded-md
            shadow-sm
            focus:border-aurora4
            focus:ring
            focus:ring-aurora4
            focus:ring-opacity-100
          "
          placeholder="ali.syed@example.com"
          required
        />
      </label>
      <label className="block mb-6">
        <span className="text-gray-700 dark:text-white transition-colors duration-300">Message</span>
        <textarea
          name="message"
          className="
            block
            w-full
            mt-1
            border-gray-300
            rounded-md
            shadow-sm
            focus:border-aurora4
            focus:ring
            focus:ring-aurora4
            focus:ring-opacity-100
          "
          placeholder="Tell me what you're thinking about..."
        ></textarea>
      </label>
      <div className="mb-6">
      <button type="submit" className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-base-100 rounded-lg bg-gradient-to-br from-aurora1 via-aurora2 via-aurora3 via-aurora4 to-aurora5 hover:ring-4 hover:ring-aurora4 dark:hover:ring-aurora4">
                    Contact
                    <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
    <ToastContainer />
      </div>
      <div>
      </div>
    </form>
  </div>
</div>
          </div>
        </div>
      </section>
    )
}

export default Form