import React from 'react';
import Card from '../../components/card/card';
import Hero from '../../components/hero/hero';
import Form from '../../components/form/form';
import Footer from '../../components/footer/footer';

const Home = () => {
    return (
        <div className='bg-white dark:bg-darkgrey transition-colors duration-300'>
        <Hero></Hero>
        <h1 id='projects' className="max-w-xl px-6 mb-4 text-[25px] font-bold font-inter tracking-tight leading-none md:text-4xl xl:text-4xl dark:text-white transition-colors duration-300">Projects</h1>
        <Card></Card>
        <h1 id='contact' className="max-w-xl px-6 mt-12 mb-4 text-[25px] font-bold font-inter tracking-tight leading-none md:text-4xl xl:text-4xl dark:text-white transition-colors duration-300">Contact</h1>
        <Form></Form>
        <Footer></Footer>
        </div>
    )
}

export default Home