import Navbar from './components/navbar/navbar';
import { BrowserRouter as Router, Route, Routes, } from 'react-router-dom';
import Home from './pages/home/home';

function App() {

  return (
    <div className='bg-transparent' style={{ maxWidth: "1080px", margin: "0 auto", float: "none"}}>
    <Router>
      <Navbar/>
        <Routes>
          <Route path='/' element={<Home />}/> 
        </Routes>
    </Router>
    </div>
  );
}

export default App;
